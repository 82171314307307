import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import annotations from "highcharts/modules/annotations";
import TableHeader from "../Page/tableHeader";
import { Card } from "@mui/material";
import styled from "styled-components";
import colors from "../../theme/colors";
import { Button, CircularProgress } from "@mui/material";
import { useRef, useEffect, useState, memo } from "react";
import { NoChartDataPlaceholder } from "../../components/NoDataPlaceholder";

annotations(Highcharts);

const NoPrintWrapper = styled.div`
  @media print {
    display: none !important;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
`;

const HighStockComponent = ({
  title,
  loaded,
  noDataPlaceholderValues = {},
  metricLabel = "",
  keyTitle = "",
  seriesData = [],
  convertLabelToLocalTime = true,
  showPrintReportButton = false,
  printReportClickHandler = () => {},
  annotations = [],
}) => {
  const chart = useRef(null);
  const [options, setOptions] = useState();
  const [highchartObject, setHighchartObject] = useState();

  const chartCallBack = (chart) => {
    setHighchartObject(chart);
  };

  useEffect(() => {
    if (highchartObject) {
      annotations.forEach((annotation, i) => {
        highchartObject.removeAnnotation(`${annotation.id}`);
        highchartObject.addAnnotation({
          draggable: "",
          id: `${annotation.id}`,
          labels: [
            {
              point: {
                xAxis: 0,
                yAxis: 0,
                x: annotation.x,
                y: annotation.y,
              },
              text: `${annotation.text}`,
            },
          ],
        });
      });
    }
  }, [annotations, highchartObject]);

  const printReportClickHandlerWrapper = () => {
    //Resize Chart
    chart.current.chart.setSize(500, 400, false);
    printReportClickHandler();
  };

  window.onafterprint = function () {
    window.location.reload();
  };

  useEffect(() => {
    if (loaded && !options) {
      setOptions({
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 800,
              },
              // Make the labels less space demanding on mobile
              chartOptions: {
                yAxis: [
                  {
                    // Primary yAxis
                    labels: {
                      format: "{value} kg",
                    },
                    title: {
                      text: "",
                    },
                    opposite: false,
                  },
                  {
                    labels: {
                      format: "{value} kg/d",
                      style: {
                        fontSize: 11,
                      },
                    },
                    title: {
                      text: "",
                    },
                    opposite: true,
                  },
                ],
                legend: {
                  enabled: true,
                  align: "left",
                  x: 65,
                  verticalAlign: "bottom",
                  floating: false,
                },
                // rangeSelector: {
                //   y: 100
                // }
              },
            },
          ],
        },
        chart: {
          height: "500",
          reflow: true,
          animation: false,
          zoomType: "x",
          zooming: {
            mouseWheel: {
              enabled: false,
            },
          },
          events: {
            load: () => {},
            render: () => {},
            redraw: () => {},
          },
        },
        scrollbar: {
          enabled: false,
        },
        rangeSelector: {
          buttons: [
            {
              type: "day",
              count: 1,
              text: "24hr",
              title: "View 24 hours",
            },
            {
              type: "week",
              count: 1,
              text: "1w",
              title: "View 1 week",
            },
            {
              type: "month",
              count: 1,
              text: "1m",
              title: "View 1 month",
            },
            {
              type: "month",
              count: 3,
              text: "3m",
              title: "View 3 months",
            },
            {
              type: "month",
              count: 6,
              text: "6m",
              title: "View 6 months",
            },
            {
              type: "ytd",
              text: "YTD",
              title: "View year to date",
            },
            {
              type: "year",
              count: 1,
              text: "1y",
              title: "View 1 year",
            },
            {
              type: "all",
              text: "All",
              title: "View all",
            },
          ],
          inputStyle: {
            color: colors.blue,
            fontSize: "13px",
          },
          labelStyle: {
            fontSize: "13px",
          },
          selected: 2,
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          ordinal: false,
          gridLineWidth: 1,
          type: "datetime",
          dateTimeLabelFormats: {
            // don't display the year
            month: "%e. %b",
            year: "%b",
          },
        },
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value} kg",
            },
            title: {
              text: "Weight",
            },
            opposite: false,
          },
          {
            labels: {
              format: "{value} kg/day",
              style: {
                fontSize: 11,
              },
            },
            title: {
              text: "Evaporation Rate",
            },
            opposite: true,
          },
        ],
        legend: {
          enabled: true,
          align: "left",
          x: 70,
          verticalAlign: "top",
          floating: false,
        },
        tooltip: {
          formatter: function () {
            // The first returned item is the header, subsequent items are the
            // points
            let header = "";
            if (convertLabelToLocalTime) {
              header = new Date(this.x).toLocaleString();
            } else {
              //format UTC date mm/dd/yyyy
              const d = new Date(this.x).toISOString().split("T")[0].split("-");
              header = `${d[1]}/${d[2]}/${d[0]}`;
            }
            return [header].concat(
              this.points
                ? this.points.map(function (point) {
                    return `${
                      point.series.name
                    }: <b>${point.y.toFixed(2)} ${point.series.name === "Evaporation Rate" ? "kg/day" : "kg"}</b>`;
                  })
                : []
            );
          },
          split: true,
        },
        plotOptions: {
          series: {
            // color: '#FF0000',
            // animation: false,
            lineWidth: 2,
            shadow: false,
            states: {
              hover: {
                lineWidth: 3,
              },
            },
            marker: {
              enabled: false,
              radius: 2,
              states: {
                hover: {
                  radius: 4,
                },
              },
            },
            fillOpacity: 0.25,
          },
        },
        series: seriesData,
      });
    } else if (loaded && options && chart?.current?.chart?.series?.length) {
      chart.current.chart.series[0].setData(seriesData[0].data);
    }
  }, [loaded, seriesData]);

  Highcharts.setOptions({
    lang: {
      rangeSelectorTo: "-",
      rangeSelectorFrom: "Date:",
      rangeSelectorZoom: "Zoom",
    },
  });

  if (loaded && !seriesData[0].data.length) {
    return (
      <Card>
        <TableHeader>
          <h2>{title}</h2>
          {showPrintReportButton && (
            <Button disabled={true} onClick={() => {}} variant="outlined">
              Print Report
            </Button>
          )}
        </TableHeader>
        <NoChartDataPlaceholder
          header={noDataPlaceholderValues.header}
          body={noDataPlaceholderValues.body}
          buttonText={noDataPlaceholderValues.buttonText}
          buttonPath={noDataPlaceholderValues.buttonPath}
        />
      </Card>
    );
  } else if (loaded) {
    // setDisplayed(true);
    return (
      <Card>
        <TableHeader>
          <h2>{title}</h2>
          {showPrintReportButton && (
            <NoPrintWrapper>
              <Button
                disabled={!loaded}
                onClick={printReportClickHandlerWrapper}
                variant="outlined"
              >
                Print Report
              </Button>
            </NoPrintWrapper>
          )}
        </TableHeader>

        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType={"stockChart"}
          ref={chart}
          callback={chartCallBack}
        />
      </Card>
    );
  } else {
    return (
      <Card>
        <TableHeader>
          <h2>{title}</h2>
        </TableHeader>
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      </Card>
    );
  }
};
export default memo(HighStockComponent);
